.uploadFileBorder {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed black;
    padding: 30px;
    text-align: center;
    cursor: pointer;
    position: relative;
  }
  
  .uploadFileBorder:hover {
    background-color: #f0f8f7; /* Light greenish background on hover */
  }
  
  .uploadFileBorder span {
    display: block;
    color: #333; /* Default text color */
  }
  
  .uploadFileBorder:active {
    border-color: darkgreen;
  }
  
  input[type="file"] {
    display: none;
  }
  
  .contactUsAdd{
    background-color: green;
  }
  .uploadText{
    font-weight: bold;
    font-family: "Roboto-Regular";
  }