@import "../variables/palette";



.rowItem {
  background-color: $light-blue;
  border-radius: 8px;
  border: 1px solid rgb(173, 172, 172);
}
.rowItemImage {
  display: flex;
  background-color: $profile-img;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
}

.bg-blue {
  background-color: $client-blue;
}
.bg-red {
  background-color: #f3002f;
}

.bg-green {
  background-color: #00a841;
}
.boxContainter {
  border-radius: 12px;
}
.practiceScore {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 20px;
  background-color: white;
}
.reportPdf {
  background-color: white;
  display: flex;
  padding: 10px;
  flex-direction: row;
  margin-top: 10px;
  border-radius: 8px;
  justify-content: center;
}

.newsFlexBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
}
.medicalPadding {
  // padding-left: 50px;
  // padding-right: 50px;
}
.bg-yellow {
  background-color: #ff9700;
}
.text-header {
  font-family: "Roboto-Bold";
}
.signatureSave {
  border: none;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 18px;
  color: white;
  background-color: #3570de;
}
.signatureClose {
  background-color: white;
  border-radius: 18px;
  padding-left: 12px;
  padding-right: 12px;
  border: 1px solid gray;
}
.selectOption {
  border: none;
  border-bottom: 1px solid lightgray;
}
.monthlyReports {
  border-radius: 12px;
  margin-left: 10px;
  background-color: $darkgray;
}

.newsItem {
  background-color: white;
  border-radius: 18px;
  box-shadow: 0px 4px 6px #a9a9a9; // Converted shadow properties to box-shadow
}
.light-blue {
  background-color: $light-blue;
}
.gray-color {
  background-color: #A6A6A9;
}
.rowItemSubHeading{
  font-family: 'Roboto-Regular';
}
.invertColor{
  filter: invert(1);
}
.border-purple {
  border-bottom: 2px solid purple;
}
.savedReportHeading{
  font-size: 28px;
  font-weight: bold;
}
.reportTableHeading{
  color: $profile-img;
  font-weight: bold;
}
.signModalText{
  color: gray;
  margin-top: 4px;
  font-size: 10px;
}
.signModalCloseText{
  text-align: center;
  font-size: 14px;
  color: gray;
}
.signModalConfirmText{
  text-align: center;
  font-size: 14px;
}

@media screen and (max-width:1000px){
  .newsFlexBox {
    flex-direction: column;
  }
}

.textDecoration {
  text-decoration: none;
}
.chevronSize {
  font-size: 8px;
}

.carousel-control-prev {
  left: -100px !important;
}
.carousel-control-next {
  right: -100px !important;
}