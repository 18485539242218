
.borderGray {
    border: 1px solid gray;
}


.fontFamily{
    font-family: "Roboto-Regular";   
}
.boldColor{
    font-family: "Roboto-Regular";
    font-weight: bold;
}

.entityDropdown{
   padding: 5px;
   border-radius: 8px;

}

.dropdownHeader{
    margin-bottom: 8px;
    font-size: 18px;
}

.orgImage{
    object-fit: contain;
    width: 100%;
    height:100px;
}

.lightGray{
    background-color: #f4f4f4;
}