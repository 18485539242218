@import '../variables/palette';
.headerpos {
    margin-top: -35px !important;
}

.backbtnContainer {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
}

.subheaderpos {
    color: #A6A6A9;
}

.datesheader {
    margin-top: -35px !important;
    color: #A6A6A9;
    font-size: medium;
}

.column {
    display: flex;
    flex-direction: column;
}

.circle-container {
    display: flex;
    justify-content: space-around;
    background-color: lightgray;
    border-radius: 100px;
    padding: 4px;
}

.circle-option {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.circle-option input[type="radio"] {
    display: none;
}

.circle-option label {
    cursor: pointer;
}

.circle-option.selected {
    background-color: white;
}

.marginfrom {
    margin-top: -30px;
}

.margtop {
    margin-top: 10px;
}

.datefont {
    font-size: 14px;
}

.linepos {
    height: 60px;
    padding-left: 10px;
}

.filtercontainer {
    display: flex;
    align-items: center;
    margin-top: 3%;
    margin-bottom: 3%;
}

.selectpos {
    margin-right: 10px;
    background-color: #E0E0E0;
}
.chevpos {
    position: absolute;
    right: 350px;
    top: 46.5%;
    transform: translateY(-50%);
    pointer-events: none;
    font-size: 18px;
    color: #333;
}

.absolute-pos{
    position: absolute;
    top:15px;
    right:0px;
}

.togglers{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    height: 30px;
    background-color: lightgray;
}

.bubble{
    background-color: rgb(175, 162, 162);
    padding: 3px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bubble-alt{
    padding: 3px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.flex-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
}

.pointer{
    cursor: pointer;
}

.search-container {
    position: relative;
    text-align: start;
    // margin-top: 20px;
  }
  .search-input {
    width: 100%; /* Make the input take the full width of the container */
  }
  
  .search-icon {
    position: absolute;
    top: 10px;
    right: 10px; 
    color: #333;
    pointer-events: none; 
  }
  .calender-container {
    position: absolute;
    border: none;
    opacity: 0;
    left: 10px;
    top: 10px;
}

.flex-col{
    display: flex;
    flex-direction: column;
}
.arrow{
    transform: rotate(360deg);
}
.arrow-rotate{
    transform: rotate(-90deg); 
}
.vertical-line-date {
    width: 0.5px;
    height: 40px;
    background-color: black;
    margin: 0 10px;
}
.gray-bg-color{
    background-color: $light-gray !important;
}
.custom-input{
    background-color:$light-gray;
    border: none;
    border-radius: 8px;
    padding: 10px;
}
.mg{
    margin-left: 10px;
}

.btn-width{
    width: 200px;
}

.light-gray{
    background-color:$lighter-gray;
}
.light-gray-alt{
    background-color:$light-gray;  
}

.invert-icon{
    filter: invert(1);
}

.custom-scroll{
    max-height: 600px;
    overflow-y: auto;
}
.custom-scroll::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

.custom-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #A6A6A9;
}


@media screen and (max-height:800px) {
    .custom-scroll{
        max-height: 350px;
        overflow-y: auto;
    }
}
.absolute-notes{
    position: fixed;
    z-index: 100000;
    right: 0px;
    top:0px;
    padding: 0px;
    background-color: white;
    border-color: #E0E0E0;
    box-shadow: 0px 4px 6px #A9A9A9; 
    width: 350px;
    height: 100%;
}

.relative-buttons{
    display: flex;
    justify-content: flex-end;
}

.border-radius-btn{
    border-radius: 22px;
    margin-right: 8px;
}
.green-btn{
    background-color: $green;
}
.red-btn{
    background-color: $red-color;
}
.clinical-input{
    height: 150px;
    width: 100%;

}

.custom-margin{
    margin-left: 10px;
    margin-right: 10px;
}
