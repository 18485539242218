@import '../variables/palette';


.dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: $light-gray;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: $light-gray;
    padding: 0;
}

.dropdown-list ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.dropdown-list li {
    padding: 10px;
    cursor: pointer;
    background-color: white;
    text-align: left;
}

.dropdown-list li:hover {
    background-color: #ddd;
}
//--------------------- SELECTED CODES ICD10 -----------------/
.selected-icd10codes {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
}

.selected-codes {
    margin-top: 10px;
    // display: flex;
    flex-wrap: wrap;
}

.selected-code {
    display: inline-flex;
    align-items: center;
    background-color: #e0e0e0;
    border-radius: 4px;
    padding: 5px 10px;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 14px;
}

.remove-icon {
    margin-left: 8px;
    cursor: pointer;
    color: #ff0000;
    font-weight: bold;
}

.pointer {
    cursor: pointer;
}

.rmdp-input {
    border: none !important;
    box-shadow: none !important;
}

.trashpos {
    padding-left: 430px !important;
    margin-top: -30px;
}

.margleft {
    margin-left: 12px;
    font-weight: bold;
    margin-bottom: -10px;
}

.button-container {
    bottom: 80px !important;
    position: absolute;
}

.savebtn {
    border-radius: 8px;
    border-color: transparent;
    background-color: $green;
    color: white;
    padding: 3px;
    width: 120px;
}

.button-container-fav {
    top: 80px;
    right: 30px;
    position: absolute;
}

.addnewfav {
    border-radius: 8px;
    border-color: transparent;
    background-color: $green;
    color: white;
    padding: 5px;
    width: 200px;
    padding-left: 30px;
}

.savebtnfav {
    border-radius: 20px;
    border-color: transparent;
    background-color: $green;
    color: white;
    padding: 3px;
    width: 140px;
}


.addadjustmentnote {
    border-radius: 8px;
    border-color: transparent;
    background-color: $green;
    color: white;
    padding: 5px;
    width: 48%;
    margin-top: 40px;
    margin-bottom: 40px;
}

.closebtn {
    border-radius: 8px;
    border-color: transparent;
    background-color: $red-color;
    color: white;
    padding: 3px;
    margin-left: 90px;
    width: 80px;
}

.datepicker-modal {
    position: absolute;
    top: 50px;
    /* Adjust based on your sidebar's content */
    left: 0;
    right: 0;
    z-index: 1000;
    /* Ensure it appears on top of other content */
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* DatePicker Wrapper */
.react-datepicker-wrapper {
    display: block;
    width: 100%;
}

.react-datepicker__input-container input {
    visibility: hidden;
}

/* DatePicker Calendar */
.react-datepicker {
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    width: 320px;
    margin-top: 20px;
    /* Adjust width as needed */
}

.react-datepicker__header {
    background-color: black;
    color: #fff;
    border-bottom: 1px solid #ddd;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    text-align: center;
    justify-content: center;
    align-self: center;
    width: 100%;
    height: 70px;
    position: absolute;
    top: 20px !important;
}

.react-datepicker__current-month {
    font-size: 20px;
    font-weight: 600;
    margin: 10px 0;
}

/* Align Days and Months in a Row */
.react-datepicker__day-names {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #333;
    margin-bottom: 5px;
}

.react-datepicker__day-name {
    flex: 1;
    text-align: center;
    color: white;
}

.react-datepicker__week {
    display: flex;
    flex-wrap: wrap;
}

.react-datepicker__day {
    border-radius: 8px;
    width: 45px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.react-datepicker__day:hover {
    background-color: #F3002F;
    color: #fff;
}

.react-datepicker__day--selected {
    background-color: #F3002F;
    color: #fff;
    border-radius: 8px;
}

/* Hide Triangle */
.react-datepicker__triangle {
    display: none;
}

/* Remove default styles for navigation buttons */
.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
    background: transparent;
    border: none;
    box-shadow: none;
}

/* Chevron styling for previous button */
.react-datepicker__navigation--previous::before {
    content: '<';
    font-size: 35px;
    color: white;
    position: absolute;
    left: 25px;
    top: 13px;
    z-index: 9999999;
}

/* Chevron styling for next button */
.react-datepicker__navigation--next::before {
    content: '>';
    font-size: 35px;
    color: white;
    position: absolute;
    right: 25px;
    top: 13px;
    z-index: 9999999;
}

/* Hide text inside the navigation buttons */
.react-datepicker__navigation--previous span,
.react-datepicker__navigation--next span {
    display: none;
}

.poslabel {
    left: 20px !important;
    position: absolute;
}

.poslabelicd {
    left: 20px !important;
    position: absolute;
    margin-top: -20px;
    border-bottom: 1px solid black;
    width: 90%;
    justify-content: flex-start;
    text-align: start;
    padding-bottom: 5px;
}

.poslabelcode {
    left: 20px !important;
    position: absolute;
    margin-top: 13px;
    width: 90%;
    justify-content: flex-start;
    text-align: start;
}

.poslabelqty {
    left: 20px !important;
    position: absolute;
    margin-top: 35px;
    width: 90%;
    justify-content: flex-start;
    text-align: start;
}

.padleftt {
    padding-left: 7px;
}

.customcontainer {
    margin-top: 100px;
}

.customcontainer2 {
    margin-top: 130px;
}

.customcontainer3 {
    margin-top: 140px;
}

.descinput {
    left: 20px !important;
    position: absolute;
    width: 89%;
    height: 50px;
    margin-top: 85px;
}

.trashicon {
    object-fit: "contain";
    background-color: white;
}

.attention {
    color: $red-color;
    align-self: center;
}

.plus-icon-fav {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    color: white;
    cursor: pointer;
    z-index: 1000;
    font-weight: bold;
}

.fontheader {
    font-weight: bold;
    font-size: 18px;
}


.input-custom-search {
    display: flex;
    padding: 10px;
    width: 80%;
    border-radius: 8px;
    text-align: center;
}

.code-table {
    padding: 10px;
}

// .code-header {
//     font-size: 1.1rem;
//     font-weight: bold;
//     border-radius: 8px;
//     background-color: $light-blue;
//     padding-top: 7px;
//     padding-bottom: 7px;
// }

.edit-icon {
    margin-left: 13px;
    cursor: pointer;
}

.trash-icon {
    margin-left: 13px;
    cursor: pointer;
    font-size: 25px;
    margin-bottom: 5px;
}

.chevron-icon {
    margin-left: 13px;
    margin-right: 13px;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
}

.code-item {
    margin-top: 2px;
    width: 33%;
    display: flex;
}

.billing-descriptions .billing-item {
    background-color: #dcdcdc;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.fontbold {
    font-weight: bold;
}

.code-section {
    width: 33%;
    display: flex;
    margin-bottom: 20px;
}

.proc-section {
    width: 33%;
}

.nappi-section {
    width: 28%;
    display: flex;
    justify-content: flex-end;
}

.code-section-code {
    width: 20%;
    display: flex;
}

.proc-section-code {
    width: 20%;
    margin-left: 293px;
    display: flex;
}

.nappi-section-code {
    width: 15%;
    display: flex;
    margin-left: 260px;
}

.generalmarg {
    margin-left: 9px;
}

.bgcolor {
    background-color: white;
}

.mainfooter {
    bottom: 40px;
    position: absolute;
    justify-content: center;
    width: 70%;
}

.codescolors {
    background-color: #292234;
    border: transparent
}

.codescolors.active {
    background-color: $gray-color;
}

// .scrollcontainerparent {
//     max-height: 590px;
//     overflow-y: auto;
//     overflow-x: clip
// }
.codeCategories{
    padding:20px !important
}
.scrollcontainer {
    max-height: 550px;
    overflow-y: auto;
    overflow-x: clip
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999999999;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    text-align: center;
}

.modal-actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
}

.cancel-button,
.delete-button {
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    color: white;
    font-weight: bold;
}

.cancel-button {
    background-color: #bfbfbf;
}

.delete-button {
    background-color: #ff1744;
}

.checkedcolor {
    color: #ff1744;
    font-weight: bold;
}

.selectedpatientscontainer {
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow-x: clip;
    overflow-y: auto;
}

.selectall {
    display: flex;
    align-items: center;
    margin-left: 15px;
}

.checkstyle {
    margin-right: 8px;
    width: 20px;
    height: 20px;
    accent-color: #4A4A4A;
    border-radius: 3px;
}
.patientGroupDropdown {
position: absolute;
z-index: 1000;
background-color: #fff;
border:1px solid #ccc ;
width: 100%;
max-height: 300px;
overflow-y: auto;
}
.patientGroupItem {
padding: 10px;
cursor: pointer;
border-bottom: 1px solid #ccc ;
} 
.submitBtn {
    width:200px;
}