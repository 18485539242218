@import '../variables/palette';

.background{
    background-color: $light-gray;
}
.pointer{
    cursor: pointer;
}
.border-raduis{
    border-radius: 6px;
}

.red{
    background-color: $red-color;
}
.row{
    padding:10px;
}
// img {
//     filter: invert(1);
// }
.medicalOption{
    filter: invert(1);
}