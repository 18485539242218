
@import '../variables/palette';
.icd10Btn{
 background-color: red;
width: 300px;
padding: 10px;
border-top-left-radius: 8px;
border-bottom-left-radius: 8px;
color: white;
}
.pointer{
    cursor: pointer;
}


.procedureCodeBtn{
    background-color: red;
   width: 300px;
   padding: 10px;
   border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    color: white;
   }
   
.procedureCodeBtnDisabled{
    border:1px solid silver;
   width: 300px;
   padding: 10px;
   border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    color: black;
   }
   .icd10BtnDisabled{
    border:1px solid silver;
    width: 300px;
    padding: 10px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    color: black;
   }
   .lightgray{
    background-color: $lighter-gray;
   }
   .custom-scroll{
    height: 50vh !important;
    overflow: auto !important;
   }
   .custom-scroll ::-webkit-scrollbar {
    width: 8px !important;
    background-color: #F5F5F5 !important;
  }
  
  .custom-scroll ::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3) !important;
    background-color: #A6A6A9 !important;
  }