
@import "../variables/_palette.scss";

.mainContainer{
    display: flex;
    width: 100%;
    position: relative;
}
.modalContainer{
    background-color: white;
    flex-direction: row;
    align-items: center;
    border-color: #E0E0E0;
    padding: 5px;
    z-index: 1;
    border-radius: 10px;
    position: absolute;
    box-shadow: 0px 4px 6px #A9A9A9; // Converted shadow properties to box-shadow
    cursor: pointer;
    margin: 10px;
    top:65px;
    right: 0px;
    width: 150px;
}
.modalDropdown {
  font-size: 12px;
  text-align: left;
  padding: 8px;
  cursor: pointer;
}

.even {
  background-color: white; 
}

.odd {
  background-color: #F4F4F4;
}
.headerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $dark-blue-color;
    padding: 1%;
 justify-content: space-between;
    width:100% ;
}
.imageContainer{
    display: flex;
    align-items: center;
    justify-content: center;
}
.xpHeader {
    padding: 5;
    margin-left: '13%';
  }
  .dotContainer{
    cursor: pointer;
    position: relative;
    right:40px;
  
  }

    .redDotContainer{ 
        background-color: $red-color;
        width: 10px;
        border-radius: 5px;
        height: 10px;
        margin-bottom:2px;
      }
      .whiteDotContainer{
        background-color: $white;
        width: 10px;
        border-radius: 5px;
        height: 10px;
        margin-bottom:2px;
      }
      .grayDotContainer{
        background-color: $gray-color;
        width: 10px;
        border-radius: 5px;
        height: 10px; 
      }

