@import '../variables/palette';

.all-radius {
    width: 46px;
    height: 46px;
    border-radius: 23px;
}

.default-radius {
    width: auto;
    height: 46px;
    border-radius: 23px;
}

.pointer {
    cursor: pointer;
}

.light-gray-bg {
    background-color: $light-dark-gray;
    border-radius: 30px;
    height: 36px;
}

.widthview {
    width: 250px;
}

.fontbold {
    font-weight: bold;
}
.queriesHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.bg-queryFilter{
    background-color:#9ea0a3;
}
.widthview:hover {
    background-color: #9b9b9b;
}


.text-success-green {
    color: $green;
    font-family: 'Roboto-Bold';
    font-size: 16px;
}

.query-txt{
    color: $red-color !important;
    font-family: 'Roboto-Bold';
    font-size: 16px;
   }
.query-text-orange {
    color: $orange;
    font-family: 'Roboto-Bold';
    font-size: 16px;
}
.query-text-green {
    color: $green;
    font-family: 'Roboto-Bold';
    font-size: 16px;
}
.normal-txt{
    font-family: 'Roboto-Regular';
    font-size: 16px;
}
.view-btn{
    background-color: $darkgray;
}
.bg-white {
    background-color: white;
  }
  
  .bg-light-gray {
    background-color: #f8f9fa; /* Light gray, you can adjust the shade if needed */
  }
  
.bold-txt{
    font-family: 'Roboto-Bold';
    font-size: 16px;
}
.about-txt {
    color: $red-color;
    font-size: 18px;
    font-family: 'Roboto-Bold', sans-serif;
    font-weight: bold;
  }
.green-txt{
    color:$green
}
.red-txt{
    color: $red-color;
}
.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    border-collapse: collapse;
    background-color: black;
}

.rounded {
    border: 1px solid #dee2e6;
    border-radius: 8px;
    overflow: hidden;
}
.profile-circle-internal {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color:$dark-blue-color;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .profile-circle-external{
    background-color: $profile-img;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: "Roboto-Bold";
  }
  .profile-initials{
    margin-top: 16px;
  }
  .subtask-box {
    flex: 1;
    flex-shrink: 1;
    padding: 5px;
    border-radius: 8px;
    border-top-left-radius:0px;
    background-color: #ffffff;
    border: 1px solid black;
    word-wrap: break-word;
  }
  .subtaskcomment-box {
    flex: 1;
    flex-shrink: 1;
    padding: 5px;
    border-radius: 8px;
    border-top-right-radius:0px;
    background-color: #ffffff;
    border: 1px solid black;
    word-wrap: break-word;
  }
  
.header-image {
    width: 50px; 
    height: 50px;
    border-radius: 25px;
    object-fit: contain; 
  }
  
.table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6;
}

.thead-light th {
    background-color: #f8f9fa;
    color: #495057;
}

.table td {
    padding: 0.75rem;
    vertical-align: top;
    text-align: left;
}

.table th {
    padding: 0.75rem;
    vertical-align: top;
    text-align: left;
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

@media screen and (max-width:700px){
    .queriesHeader{
        display: flex;
        flex-direction: column;
    }
}