.modal {
    // display: none;
    position: fixed;
    z-index: 1;
    left: 20;
    top: 20;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
  }
  
  .modal.show {
    display: flex;
  }
  
  .modal-container {
    background-color: white;
    // padding: 20px;
    border-radius: 10px;
    // max-width: 400px;
    // text-align: center;
  }
  
  .modal-title {
    font-size: 16px;
    font-family: 'Roboto-Regular';
    margin-top: 15px;
    margin-bottom: 10px;
    text-align: left;
  }
  
  .picker-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .picker {
    flex: 1;
    margin: 0 5px;
    border-radius: 8px;
    padding: 10px;
    font-size: 16px;
  }
  .paddingBox{
    padding: 20px !important;
  }
  .confirm-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .confirm-button:hover {
    background-color: #0056b3;
  }
  