@import '../variables/palette';

.container {
  background-color: #ffffff;
  width: 100%;
}

.row {
  padding: 50px;
}

.login-row {
  margin-bottom: 5px;
}

input.form-control {
  border-radius: 5px;
  padding: 10px;
  border: 1px solid $gray-color;
  color: $dark-blue-color;
  background-color: "#000000";
  font-family: "Roboto-Regular";
}

.headerImage {
  height: 40%;
  width: 70%;
}

.button-bg {
  background-color: $dark-blue-color;
}

.vectorBig {
  position: absolute;
  top: 10%;
  right: 10%;
  width: 250px;
  height: 250px;
  z-index: 1;
}

.page-bg-img {
  background-image: url('../../assets/Xpedient_Grey_Background.png');
  background-size: cover;
  background-position: center;
  height: 100vh;
  position: relative;
}

.termsConditions {
  color: $dark-blue-color;
}

.vectorFullBGImage {
  position: absolute;
  top: 50%;
  right: 20%;
  width: 150px;
  height: 150px;
  z-index: 1;
}

.vectorFullBGImageLeft {
  position: absolute;
  top: 60%;
  left: -1%;
  width: 250px;
  height: 250px;
  z-index: 1;
}

.vectorImageRight {
  position: absolute;
  top: 20%;
  left: 1.5%;
  width: 100px;
  height: 100px;
  z-index: 1;
}

.authContainer {

  display: flex;
  z-index: 2;
  position: relative;
  max-width:500px;
  padding: 20px;
}

.loginText {
  font-size: 18px;
  font-family: "Roboto-Bold";
  margin-top: 10px;
}

.passwordText{
  font-size: 18px;
  font-family: "Roboto-Regular";
  margin-top: 10px;

}
.form-group {
  padding-left: 180px;
  padding-right: 180px;

  .text-danger {
    display: block;
    text-align: left;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }

  .textinput {
    display: block;
    text-align: left;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
}

label {
  display: block;
  text-align: left;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.link-text {
  text-decoration: none;
  color: $dark-blue-color;
  display: block;
  text-align: right;
  font-family: "Roboto-Bold";

  &:hover {
    text-decoration: underline;
  }

}

.login-text {
  text-decoration: none;
  color: white;
  display: block;
  text-align: right;
  font-family: "Roboto-Bold";

  &:hover {
    text-decoration: underline;
  }

  background-color: $dark-blue-color;
}

/// --------------------- MODAL =---------------------------///
.confirmationModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears above other content */
}

.modalContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}
.modalContent button {
  padding: 10px 20px;
  margin: 10px; /* Space between buttons */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s; /* Smooth transitions for hover effects */
}

.modalContent button:first-child {
  background-color: #ff4d4d; /* Red color for "Yes" */
  color: white;
}

.modalContent button:first-child:hover {
  background-color: #ff1a1a; /* Darker red on hover */
}

.modalContent button:last-child {
  background-color: #4CAF50;
  color: white;
}

.modalContent button:last-child:hover {
  background-color: #45a049; 
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 500px;
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000; // Ensure the modal is on top of other content
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.margintopsuccess {
  margin-top:20px;
}

.eyePos {
  position: absolute;
  right:5px;
  top:12px;
  font-size: 12px;
}