.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-content {
  text-align: center;
  color: white;
}

h3 {
  margin: 10px 0;
}

.dots {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.redDot {
  margin-left: 10px;
  margin-right: 10px;
  color: #f3002f;
}

.grayDot {
  margin-left: 10px;
  margin-right: 10px;
  color: #a9adaf;
}

.blackDot {
  margin-left: 10px;
  margin-right: 10px;
  color: #292234;
}
