


.billinghistoryHeader{
font-size: 16px;
color: gray;
font-family: "Roboto-Regular";
}


.billinghistoryText{
    font-size: 14px;
    font-family: "Roboto-Bold";
}