@import '../variables/palette';

.background-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px ;
    width: 100%;
    margin:10px;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease; 
  
    &:hover {
      box-shadow: 0 8px 8px rgba(14, 16, 130, 0.3); 
    }
  
    // &.clicked {
    //   transform: scale(1.02); 
    //   box-shadow: 0 12px 9px rgba(14, 16, 130, 0.3); 
    //   transition: transform 0.2s ease, box-shadow 0.2s ease; 
    // }
  
    &.profileColor {
      background-color: #3E4E63;
      color: white;
    }
  
    &.white {
      background-color: white;
      border: 1px solid black;
    }
  
    &.red {
      color: white;
      background-color: $red-color;
    }
  }
  .background-btn-alt {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px ;
    width: 100%;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  
    .profileColor{
    background-color: #3E4E63;
    color: white;
    }
    .clinicalNotesBtn{
        background-color: $profile-img;
        color: white;
    }

.red{
    color: white;
    background-color: $red-color;
}
.white{
    background-color: white;
    box-shadow: 0px 4px 6px #A9A9A9;
    border: 1px solid black;
}

.gray{
    background-color: #d9d9d9;
}
.darkred{
    background-color: #a5002f;
    color:white;
}
.bold-txt{
    font-family: "Roboto-Bold";
}
.input-custom{
    display: flex;
    padding: 10px;
    // width: 70%;
    border-radius: 8px;
    text-align: center;    
}
.raduis{
    border-radius: 8px;
}
.search-padding{
    font-family: 'Roboto-Regular';
    padding: 5px;

  }
  .bg-field{
    background-color: white !important;
  }
.absolute-pos{
    position: absolute;
    top:40px;
    right:0px;
}
.centered-items{
    display: flex;
    align-items: center;
    justify-content: center;
}
.position-relatives{
    position: relative;

}

.mgNotFound{
    margin-top: 100px;
}

@media screen and (max-width:1000px){
    .absolute-pos{
        top:56%;
        left:35%;
    }  
    .background-btn{
      margin: 0px;
      margin-top: 20px;
    }
}

