@import "../variables/palette";
.custom-border {
  border-bottom: 1px solid purple;
  width: 100px;
}

span {
  font-family: "Roboto-Regular";
}
.pointer {
  cursor: pointer;
}

.bg-light {
  background-color: #f5f5f5;
}
.white-custom {
  background-color: white;
  box-shadow: 0px 4px 6px #a9a9a9; // Converted shadow properties to box-shadow
  border-color: #e0e0e0;
  border-radius: 8px;
}
.text-red {
  color: #a5002f;
}
.text-blue {
  color: $dark-blue-color;
  font-size: 16px;
}
.daily-item {
  background-color: $profile-img;
}
.text-darkblue {
  color: $profile-img;
}
.bold-color {
  font-weight: bold;
}

.bottom-tab {
  display: flex;
  justify-content: space-between;
}

.bottom-tabs {
  padding: 0px 10px;
}

// @media screen and (max-width:800px){

// .bottom-tab{
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;

// }

// .daily-item{
//   margin-top: 10px;
// }
// .white-custom{
//     margin-top: 10px;
// }
// }

.bottomBorderPurple {
  border-bottom: 1px solid purple;
  border-width: 2px;
}
.bottomBorderBlack {
  border-bottom: 1px solid black;
  border-width: 2px;
  font-size: 16px;
  font-family: "Roboto-Bold";
  text-align: left;
}
.bottomBorderGray {
  border-bottom: 1px solid gray;
  border-width: 2px;
}
.bottomBorderGrayLight {
  border-bottom: 1px solid lightgray;
  border-width: 2px;
}
.monthly-custom {
  background-color: #f5f5f5;
  margin: 10px;
}
.monthlyIcon {
  border-radius: 60px;
  width: 120px;
  height: 120px;
}
.w-90 {
  width: 90%;
}
.bottom-pos {
  position: absolute;
  bottom: 100px;
}
.right-pos {
  right: 10px;
}
.download-btn {
  padding: 10px;
  /* border: none; */
  color: black;
  border-radius: 12px;
  background-color: $light-gray;
  margin-left: 40px;
  border-color: black;
  font-family: "Roboto-Regular";
  border-width: 1px;
  font-size: 12px;
}
.border-rounded {
  border: none;
  border-radius: 8px;
}

.absolute-element {
  position: absolute;
  width: 30px;
  top: -10px;
  right: -50px;
  opacity: 0;
}
.dateDivider {
  width: 2px; 
  height: 80px; 
  background-color: black; 
  margin: 10px; 
}
