// .absolute-notes{
//     position: absolute;
//     z-index: 1;
//     right: 0px;
//     top:0px;
//     padding: 0px;
//     background-color: white;
//     border-color: #E0E0E0;
//     box-shadow: 0px 4px 6px #A9A9A9; 
//     width: 350px;
  
// }

// .custom-margin{
//     margin-left: 10px;
//     margin-right: 10px;
// }


.absolute-notes {
    position: fixed;
    top:0px;
    right: 0;
    width: 350px;
    height: 100vh;
    background: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
    transform: translateX(100%);
    transition: transform 0.3s ease;
    z-index: 99999999;
  }
 
  .absolute-notes.open {
    transform: translateX(0);
  }

  .custom-margin{
        margin-left: 10px;
     margin-right: 10px;
  }