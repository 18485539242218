.carousel-indicators {
   bottom: -10px;
}
.showHideDesc {
display: block;
}

@media screen and (max-width:1200px){
    .showHideDesc {
        display: none;
    }
}