/* CustomScroll.css */
.custom-scroll-container {
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Hide horizontal scrolling */
  padding: 10px; /* Optional padding */
}

.custom-smaller-scroll-container {
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Hide horizontal scrolling */
}

/* Custom scrollbar styles */
.custom-scroll-container::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.custom-scroll-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #A6A6A9;
}

/* Custom scrollbar styles */
.custom-smaller-scroll-container::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.custom-smaller-scroll-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #A6A6A9;
}
