/* Backdrop style */

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Darken the backdrop */
    z-index: 1; /* Ensure it's below the modal */
  }
  .bold-font{
font-weight: bold;
  }
  /* Modal container style */
  .card {
    background-color: white; /* Bright background for the modal */
    flex-direction: row;
    padding: 20px; /* Adjust padding as needed */
    border-radius: 15px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    cursor: pointer;
    position: fixed; /* Use fixed positioning for modal */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the element */
    z-index: 2; /* Ensure it's above the backdrop */
}
  .selected-note{
    height: 100px;
    overflow-y: scroll;
     width:300px;
  }
  .selected-note::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
  }
  
  .selected-note::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #A6A6A9;
  }