.pointer {
    cursor: pointer;
}

.checkpos {
    margin-right: 20px !important;
    margin-top: -35px !important;
}

// Media query for phones
// @media (max-width: 600px) {
//     .column {
//         width: 160%;
//     }

//     .posrow {
//         margin-left: -20px;
//     }
// }

