 @import '../variables/palette';

 .dropdown-list {
   position: absolute;
   top: 100%;
   left: 0;
   background-color: $light-gray;
   border: 1px solid #ccc;
   border-radius: 4px;
   width: 100%;
   max-height: 200px;
   overflow-y: auto;
   z-index: 1000;
   box-shadow: $light-gray;
   padding: 0;
 }
 .searchIcon {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  color: black;
  font-size: 18px;
  position: 'absolute', 
 }
 .dropdown-list ul {
   list-style-type: none;
   padding: 0;
   margin: 0;
 }

 .dropdown-list li {
   padding: 10px;
   cursor: pointer;
   background-color: white;
   text-align: left;
 }

 .dropdown-list li:hover {
   background-color: #ddd;
 }

 .plus-icon {
   position: absolute;
   top: 50%;
   right: 10px;
   transform: translateY(-50%);
   color: #888;
   cursor: pointer;
   z-index: 1000;

 }
 //--------------------- SELECTED CODES ICD10 -----------------/
 .selected-icd10codes {
   margin-top: 10px;
   display: flex;
   flex-wrap: wrap;
 }

 .selected-codes {
   margin-top: 10px;
   // display: flex;
   flex-wrap: wrap;
 }

 .selected-code {
   display: inline-flex;
   align-items: center;
   background-color: #e0e0e0;
   border-radius: 4px;
   padding: 5px 10px;
   margin-right: 5px;
   margin-bottom: 5px;
   font-size: 14px;
 }

 .remove-icon {
   margin-left: 8px;
   cursor: pointer;
   color: #ff0000;
   font-weight: bold;
 }

 .pointer {
   cursor: pointer;
 }

 .trashpos {
   padding-left: 430px !important;
   margin-top: -30px;
 }

 .margleft {
   margin-left: 12px;
   font-weight: bold;
   margin-bottom: -10px;
 }

 .trashcan {
   position: absolute;
   top: -30px;
   right: -25px;
 }

 .line {
  margin-bottom: 15px;
   border-top: 1px solid #cac4d0;
   width: 100%;
 }

 .button-container {
   bottom: 80px !important;
   position: absolute;
 }

 .favourite-add {
   border-radius: 20px;
   border-color: transparent;
   background-color: $green;
   color: white;
   padding: 5px 10px;
   margin-right: 5px;

 }

 .favourite-close {
   border-radius: 20px;
   border-color: transparent;
   background-color: $red-color;
   color: white;
   padding: 5px 10px;
 }

 .savebtn {
   border-radius: 20px;
   border-color: transparent;
   background-color: $green;
   color: white;
   padding: 3px;
   width: 120px;
 }

 .button-container-fav {
   top: 80px;
   right: 30px;
   position: absolute;
 }

 .favourites-main-header {
   font-size: 18px;
   font-weight: bold;
   text-align: left;
 }

 .favourite-close {
   border-radius: 20px;
   border-color: transparent;
   background-color: $red-color;
   color: white;
   padding: 5px 10px;
 }

 .favourite-add {
   border-radius: 20px;
   border-color: transparent;
   background-color: $green;
   color: white;
   padding: 5px 10px;
   margin-right: 5px;

 }

 .heading-spacing {
   margin-top: 50px;
 }

 .addnewfav {
   border-radius: 8px;
   border-color: transparent;
   background-color: $green;
   color: white;
   padding: 5px;
   width: 200px;
   padding-left: 30px;
 }

 .description-input {
   height: 50px;
   width: 100%;
   border-radius: 8px;
   border-width: 1px;
 }

 .savebtnfav {
   border-radius: 20px;
   border-color: transparent;
   background-color: $green;
   color: white;
   padding: 3px;
   width: 140px;
   margin-right: 5px;
 }

 .code-labels {
   text-align: start;
   align-items: start;

 }

 .addcnote {
   border-radius: 8px;
   border-color: transparent;
   background-color: $green;
   color: white;
   padding: 5px;
   width: 100%;
 }

 .addadjustmentnote {
   border-radius: 8px;
   border-color: transparent;
   background-color: $green;
   color: white;
   padding: 5px;
   width: 48%;
   margin-top: 40px;
   margin-bottom: 40px;
 }

 .closebtn {
   border-radius: 20px;
   border-color: transparent;
   background-color: $red-color;
   color: white;
   padding: 3px;
   width: 80px;
 }

 /* DatePicker Wrapper */
 .react-datepicker-wrapper {
   display: block;
   width: 100%;
 }

 .react-datepicker__input-container input {
   visibility: hidden;
 }

 /* DatePicker Calendar */
 .react-datepicker {
   border-radius: 8px;
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
   background-color: #fff;
   width: 320px;
   margin-top: 20px;
   /* Adjust width as needed */
 }

 .react-datepicker__header {
   background-color: black;
   color: #fff;
   border-bottom: 1px solid #ddd;
   border-top-left-radius: 12px;
   border-top-right-radius: 12px;
   text-align: center;
   justify-content: center;
   align-self: center;
   width: 100%;
   height: 70px;
   position: absolute;
   top: 20px !important;
 }

 .react-datepicker__current-month {
   font-size: 20px;
   font-weight: 600;
   margin: 10px 0;
 }

 /* Align Days and Months in a Row */
 .react-datepicker__day-names {
   display: flex;
   justify-content: space-between;
   font-size: 16px;
   color: #333;
   margin-bottom: 5px;
 }

 .react-datepicker__day-name {
   flex: 1;
   text-align: center;
   color: white;
 }

 .react-datepicker__week {
   display: flex;
   flex-wrap: wrap;
 }

 .react-datepicker__day {
   border-radius: 8px;
   width: 45px;
   height: 45px !important;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 16px;
   color: #333;
   cursor: pointer;
   transition: background-color 0.3s ease;
 }

 .react-datepicker__day:hover {
   background-color: #F3002F;
   color: #fff;
 }

 .react-datepicker__day--selected {
   background-color: #F3002F;
   color: #fff;
   border-radius: 8px;
 }

 /* Hide Triangle */
 .react-datepicker__triangle {
   display: none;
 }

 /* Remove default styles for navigation buttons */
 .react-datepicker__navigation--previous,
 .react-datepicker__navigation--next {
   background: transparent;
   border: none;
   box-shadow: none;
 }

 /* Chevron styling for previous button */
 .react-datepicker__navigation--previous::before {
   content: '<';
   font-size: 35px;
   color: white;
   position: absolute;
   left: 50px !important;
   top: 13px;
   z-index: 9999999;
 }

 /* Chevron styling for next button */
 .react-datepicker__navigation--next::before {
   content: '>';
   font-size: 35px;
   color: white;
   position: absolute;
   right: 50px !important;
   top: 13px;
   z-index: 9999999;
 }

 /* Hide text inside the navigation buttons */
 .react-datepicker__navigation--previous span,
 .react-datepicker__navigation--next span {
   display: none;
 }

 .poslabel {
   left: 20px !important;
   position: absolute;
 }

 .poslabelicd {
   left: 20px !important;
   position: absolute;
   margin-top: -20px;
   border-bottom: 1px solid black;
   width: 90%;
   justify-content: flex-start;
   text-align: start;
   padding-bottom: 5px;
 }

 .favourites-heading {
   text-align: start;
   border-bottom: 1px solid black;
   font-size: 16px;
 }
 .main-sidenav {
  display: flex;                
  flex-direction: column;     
  height: 100%;                
}
.body-sidenav{
  flex: 1;
  padding: 10px;
}
.closing-sidenav{
  flex: 1;
  padding: 10px;
}
.position-close {
  display: flex; 
  justify-content: center; 
  align-items: center; 
  height: 100%; 
}


 .poslabelcode {
   left: 20px !important;
   position: absolute;
   margin-top: 13px;
   width: 90%;
   justify-content: flex-start;
   text-align: start;
 }

 .favourites-spacing {
   margin-bottom: 20px;
 }

 .poslabelqty {
   left: 20px !important;
   position: absolute;
   margin-top: 35px;
   width: 90%;
   justify-content: flex-start;
   text-align: start;
 }

 .customcontainer {
   margin-top: 100px;
 }

 .customcontainer2 {
   margin-top: 130px;
 }

 .customcontainer3 {
   margin-top: 140px;
 }

 .trashicon {
   object-fit: "contain";
   background-color: white;
 }

 .attention {
   color: $red-color;
   align-self: center;
 }

 .plus-icon-fav {
   position: absolute;
   top: 50%;
   left: 15px;
   transform: translateY(-50%);
   color: white;
   cursor: pointer;
   z-index: 1000;
   font-weight: bold;
 }
 
  .fontheader {
   font-weight: bold;
   font-size: 18px;
 }

 .lightColor {
   background-color: $lighter-gray;
   border-radius: 8px;
 }
 .favourite-margin{
    margin-left: 12px;
    padding-right: 22px;
 }
.text-font{
  background-color: "white" !important;
  font-family: "Roboto-Regular" !important;
  color: "black" !important;
}

 .code-table {
   padding: 10px;
 }

 .code-table-bq {
   padding-right: 30px;
   width: 100%;

 }

 .code-header {
   font-size: 1.1rem;
   font-weight: "Roboto-Bold";
   border-radius: 8px;
   background-color: $light-gray;
   padding-top: 7px;
   padding-bottom: 7px;
 }
 .favourite-header {
  font-size: 1.1rem;
  font-weight: "Roboto-Regular";
  border-radius: 8px;
  background-color: $light-gray;
  padding-top: 7px;
  padding-bottom: 7px;
}
 .red-bold-txt{
  color: $red-color;
  font-family: 'Roboto-Bold';
  font-size: 16px;
 }
 .code-header-qd {
   font-size: 1.1rem;
   font-weight: bold;
   border-radius: 8px;
   background-color: $red-color;
   padding-top: 7px;
   padding-bottom: 7px;
 }

 .edit-icon {
   margin-left: 13px;
   cursor: pointer;
 }

 .trash-icon {
   margin-left: 13px;
   cursor: pointer;
   font-size: 25px;
   margin-bottom: 5px;
 }

 .chevron-icon {
   margin-left: 13px;
   margin-right: 13px;
   cursor: pointer;
   font-size: 20px;
   font-weight: bold;
 }

 .billing-descriptions .billing-item {
   background-color: #dcdcdc;
   padding: 10px;
   margin-top: 10px;
   border-radius: 5px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   cursor: pointer;
 }

 .code-section {
   width: 33%;
   display: flex;
   margin-bottom: 20px;
 }
 .nappi-section-code {
   width: 15%;
   display: flex;
   margin-left: 260px;
 }

 .generalmarg {
   margin-left: 9px;
 }

 .bgcolor {
   background-color: white;
 }

 .mainfooter {
   bottom: 40px;
   justify-content: center;
   width: 100%;
 }

 .codescolors {
   background-color: #292234;
   border: transparent
 }

 .codescolors.active {
   background-color: $gray-color;
 }

 .scrollcontainerparent {
   max-height: 590px;
   overflow-y: auto;
   overflow-x: clip;
   padding-top: 20px;
   padding-bottom: 20px;
 }

 .scrollcontainer {
   max-height: 550px;
   overflow-y: auto;
   overflow-x: clip
 }


 .modal-overlay {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background: rgba(0, 0, 0, 0.5);
   display: flex;
   align-items: center;
   justify-content: center;
   z-index: 9999999999;
 }

 .modal-content {
   background: white;
   padding: 20px;
   border-radius: 8px;
   width: 400px;
   text-align: center;
 }

 .modal-actions {
   margin-top: 20px;
   display: flex;
   justify-content: space-around;
 }

 .cancel-button,
 .delete-button {
   border: none;
   padding: 10px 20px;
   border-radius: 20px;
   cursor: pointer;
   color: white;
   font-weight: bold;
 }

 .cancel-button {
   background-color: #bfbfbf;
 }

 .delete-button {
   background-color: #ff1744;
 }

 .checkedcolor {
   color: #ff1744;
   font-weight: bold;
 }
 .multipleDatePicker {
  border: none;
  position: absolute;
  top: 0;
  left: 10px;
  width: 90%;
  height: 55%;
  cursor: pointer;
  background-color: red;
}
.nappiCodeMrgn{
  margin-top: 10px;
}
.prodCodeMrgn{
  margin-top: 11px;
}