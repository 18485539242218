//colors
$gray-color: #A6A6A9;
$red-color: #F3002F;
$black-color: #292234;
$dark-blue-color: #292234;
$light-dark-gray: #E0E0E0;
$light-gray:#F4F4F4;
$white: white;
$green: #0c8800;
$light-blue: #d4dae1;
$profile-img: #3E4E63;
$lighter-gray: #F4F4F4;
$orange: #eb8510;
$darkgray:#9EA0A3;
$light-blue:#e9ecef;
$client-blue:#607a9b;

// Font Variables
$font-roboto-bold: 'Roboto-Bold';
$font-roboto-regular: 'Roboto-Regular';

// Spacing Variables
$padding-small: 5px;
$margin-top-small: 10px;
$border-radius: 15px;

// Size Variables
$calendar-icon-height: 25px;
$heading-font-size: 16px;
$error-font-size: 12px;