@import "../variables/palette";

.wrapper {
  display: flex;
  width: 5%;
  min-width: 70px;
  position: relative;

  z-index: 9999;
}
.main {
  min-height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  transition: all 0.35s ease-in-out;
  display: flex;
  align-items: center;
  background-color: $dark-blue-color;
  flex-direction: column;
}
.query-notification {
  background-color: $red-color;
  color: white;
  border-radius: 50%;
  font-size:11px;
  position: absolute;
  top: 260px;
  right: 90px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.random {
  position: fixed;
  z-index: 99999999;
  width: 15%;
  min-width: 250px;
}
.main-expanded {
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  transition: all 0.35s ease-in-out;
  display: flex;
  flex-direction: column;
  background-color: $dark-blue-color;
  height: 100%;
}
.expand {
  position: relative;
  width: 15%;
  min-width: 250px;
}
.bars {
  height: 4px;
  margin: 3px;
  width: 22px;
}
.full-width {
  width: 100%;
}
.background-modal {
  background-color: red;
}
.dot {
  display: none;
}
.main-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
.flex-grow{
  flex-grow:1
}
.top-bar {
  display: flex;
  flex-direction: row;
  background-color: white;
  border-bottom: 1px solid gray;
  border-color: #e0e0e0;
  width: 100%;
  height: 70px;
}
.notification-count {
  display: inline-flex;
  align-items: center;
}

.badge {
  padding: 0.2em 0.5em;
  border-radius: 0.5em;
  color: white; /* Adjust text color as necessary */
}

.initials {
  display: flex;
  width: 100%;
  margin-right: 20px;
  justify-content: flex-end;
}
@media screen and (max-width: 500px) {
  .initials {
    display: flex;
  }
  .t-text {
    font-size: 10px;
  }
}

.bold-color {
  font-weight: bold;
}
.profile {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: $profile-img;
  display: flex; /* Enable flexbox for alignment */
  align-items: center; /* Center text vertically */
  justify-content: center; /* Center text horizontally */
  // margin-right: 15px;
}
.initials-text {
  display: flex;
  flex-direction: column;
  text-align: end;
  margin-right: 5px;
}

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 350px;

  height: 100%;
  background: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
  transition: transform 0.3s ease;
  z-index: 99999999;
}

.sidebar.open {
  transform: translateX(0);
}

.sidebar-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sidebar-btn {
  width: auto;
  height: 50px;
  background-color: $light-blue;
  margin: 10px;
  border-radius: 8px;
  display: flex;
}
.active-sidebar {
  display: flex;
  width: auto;
  height: 50px;
  background-color: white;
  margin-left: 10px;
  margin-top: 10px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.column {
  display: flex;
  flex-direction: column;
}
.bold {
  font-weight: 500;
  margin: 11px;
}
.marginbtn {
  width: 100%;
  margin-top: 70px;
  font-family: "Roboto-Bold";
}
.flexrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
  justify-content: center;
  justify-content: space-around;
}
.smallpadding {
  width: auto;
  height: 50px;
  background-color: $light-blue;
  margin: 10px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.sidebar-img {
  margin-left: 5px;
  align-self: center; /* Center the image vertically within the button */
}

.sidebar-img-expanded {
  align-self: center; /* Center the image vertically within the button */
}
.show-on-small {
  display: none;
}

.xpimg {
  margin-top: 5px;
  width: 140px;
}

.pointer {
  cursor: pointer;
}
@media screen and (max-width: 700px) {
  .wrapper {
    width: 0%;
    position: none;
    display: none;
    min-width: none;
  }
  .main {
    width: 0%;
    position: none;
    display: none;
  }
  .show-on-small {
    display: block;
    padding-top: 8px;
  }
}
.sidebar-container {
  overflow-y: auto;
  max-height: 85vh; /* Adjust height as needed */
}

/* Hide scrollbar for WebKit browsers */
.sidebar-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}

/* Hide scrollbar for Firefox */
.sidebar-container {
  scrollbar-width: none; /* Hide scrollbar */
}

.dropdown-menu-bg {
  background-color: white;
  transition: background-color 0.3s ease;
  width: 34px;
  border-radius: 17px;
  height: 34px;
  align-items: center;
  justify-content: center;
}
.dropdown-menu-bg:hover {
  background-color: #e7e5e5;
}
.dropdown-ellipses {
  width: 4px;
  height: 4px;
  background-color: rgb(48, 43, 43);
  margin-top: 2px;
  border-radius: 2px;
}
@media screen and (max-width: 400px) {
  .dropdown-menu-bg {
    background-color: white;
    transition: background-color 0.3s ease;
    width: 26px;
    border-radius: 13px;
    height: 26px;
    align-items: center;
    justify-content: center;
  }
}
