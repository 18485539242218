// Import the variables
@import '../variables/palette';

.text-value {
  color: $red-color;
  font-size: 18px;
  font-family: 'Roboto-Bold', sans-serif;
  font-weight: bold;
  letter-spacing: 3px;
}
.text-driven {
    color: $dark-blue-color;
    font-size: 18px;
    font-family: 'Roboto-Bold', sans-serif;
    letter-spacing: 3px;
  }
  .text-solutions {
    color: $gray-color;
    font-size: 18px;
    font-family: 'Roboto-Bold', sans-serif;
    font-weight: bold;
    letter-spacing: 3px;
  }
  .textTwo {
    color: $black-color;
    font-size: 18px;
    font-family: "Roboto-Bold";
    letter-spacing: 3px;
    margin-left: 15px;
    margin-right: 15px;
  }