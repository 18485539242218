@import "../variables/_palette.scss";


span{
  font-family: 'Roboto-Regular';
}
h5{
  font-family: 'Roboto-bold';
}
small{
  font-family: 'Roboto-Regular';
  
}


.mainContainer{
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    width: 100%;

}
.cardContainer {
    background-color: white;
    flex-direction: row;
    border-color: #E0E0E0;
    padding: 5px;
    border-radius: 15px;
    box-shadow: 0px 4px 6px #A9A9A9; 
    cursor: pointer;
    margin-top: 10px;  
}
textarea:focus, input:focus{
  outline: none;
}
.calendar-icon {
  position: absolute; 
  right: 10px; 
  top: 50%; 
  transform: translateY(-50%);  
  height: 25px; 
  pointer-events: none;
}
.error-text{
  font-family: 'Roboto-Bold';
  font-size: 12px;
  color: $red-color;

}
.bold{
  font-family: "Roboto-Bold" !important;
  font-size: 16px !important;
  margin-left: 15px ;
}
.sidebar-title{
  text-transform: uppercase;
  padding-left: 22px !important;
}
.react-datepicker__header {
  background-color: white !important;
  color: black !important;
}
.react-datepicker__navigation--previous::before {
  content: "<";
  font-size: 35px;
  color: black !important;
}
.react-datepicker__navigation--next::before {
  color: black !important;
}
.react-datepicker__day-name {
  flex: 1 1;
  text-align: center;
  color: black !important;
}

.buttons-align{
    display: flex;              
    justify-content: space-between; 
    align-items: center;       
    width: 100%; 
    margin-top: 40%;              
  
}
.heading {
  font-family: "Roboto-Bold" !important;
  font-size: 16px !important;
  margin-left: 15px !important;
}
.backbtnContainer{
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.align-details{
  padding: 0.5px !important;
  font-family: "Roboto-Regular";
}
.responsive-flex{
  display:flex;
  justify-content:'space-around';
  width:100%
}

@media screen and (max-width:1000px) {
  .responsive-flex{
    display:flex;
    justify-content:'space-around';
    flex-direction: column;
    width:100%
  } 
  @media screen and (max-width:600px){
    .heading {
      font-family: "Roboto-Bold" !important;
      font-size: 10px !important;
      margin-left: 5px !important;
    }
  }
}
