@import '../variables/palette';


.formButton {
  background-color: $dark-blue-color;
  border-radius: 8px;
  padding: 0.8rem;
  margin-bottom: 5px;
  margin-top: 10px;
  cursor: pointer;
  border-color: $dark-blue-color;
}
.clinicalNoteButton {
  background-color: $gray-color;
  border-radius: 8px;
  padding: 0.8rem;
  margin-bottom: 5px;
  margin-top: 10px;
  cursor: pointer;
  border-color: $gray-color;
}
.divider {
  border: none; 
  border-bottom: 1px solid #090909;
  margin: 5px 0; 
}
.formButtonPatientDetails {
  background-color: #D3D3D3;
  border-radius: 8px;
  padding: 0.2rem;
  width: 45%;
  margin-bottom: 5px;
  margin-top: 3px;
  cursor: pointer;
  border-color: $dark-blue-color;
}

.formButtonInvoiceDetails {
  background-color: #3e4e65;
  border-radius: 8px;
  padding: 0.8rem;
  margin-bottom: 5px;
  margin-top: 10px;
  cursor: pointer;
  border-color: transparent;
}

.font-bold {
  font-family: 'Roboto-Bold', sans-serif;
  color: $dark-blue-color;
}



//SPINNER TO USE GLOBALLY
.spinner-border {
  width: 1.5rem;
  height: 1.5rem;
  border: 0.2em solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spinner-border .75s linear infinite;
  margin-right: 0.5rem;
}

@keyframes spinner-border {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}