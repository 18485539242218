.uploaddoccontainer {
    border-radius: 12px;
    border-color: transparent;
    text-align: center;
    padding:20px
}


.cloudpos {
    margin-top: -33px;
    margin-left: -138px;
}

.postext {
    margin-top: -28px;
}

.preview {
    margin-top: 4%;
    font-weight: bold;
    color: #F3002F;
    text-decoration: underline;
    cursor: pointer;
}

.nofilepos {
    margin-top: 2%;
}

.addnotesStyles {
    max-height: 200px;
    overflow-y: auto;
}

.subcolor {
    background-color: #0c8800;
    border-color: transparent;
}
.subcolorBlue {
    background-color: #3E4E63;
    border-color: transparent;
}