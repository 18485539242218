@import '../variables/palette';
.mobile-message {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    background-color: white;
    padding: 12px;
    border-bottom: 1px solid gray;
    text-align: center;
  }
  
  .boldColor {
    font-weight: bold;
  }

  .openButton {
    border: none;
    background-color: $dark-blue-color;
    color: white;
    font-size: 12px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 14px;
  }
  